<template>
  <div class="px-5">
    <div class="flex justify-between items-center">
      <div class="flex flex-col">
        <span
          class="text-black text-3xl font-bold whitespace-no-wrap font-SourceSansPro"
        >
          {{ $t(`new_units.titles.${activeTab}`) }}
        </span>
      </div>
      <div class="flex items-center gap-4">
        <!-- HIDE FOR NOW INFORMATION ICON -->
        <!-- <InformationIcon class="cursor-pointer tool-icon" /> -->
        <HistoryIcon
          class="cursor-pointer tool-icon"
          @click.native="$emit('onShowHistory')"
        />
        <CloseIcon
          v-if="!isModify"
          class="cursor-pointer tool-icon"
          @click.native="$emit('onClose')"
        />
        <BackIcon
          v-else
          class="cursor-pointer tool-icon"
          @click.native="$emit('onBack')"
        />
      </div>
    </div>
    <span v-if="isModify" class="greyToneSecond"
      >{{ $t('new_units.objects.object') }} {{ objectName }}</span
    >
    <div
      v-if="!isModify"
      class="col-start-1 col-end-3 pb-3 mt-6 flex gap-4 justify-between items-center"
    >
      <UnitsSearchInput
        v-model="searchValue"
        :search-model="searchModel"
        :options="searchOptions"
        :tab="activeTab"
        @onOptionChange="searchModel = $event"
        @onInput="searchValue = $event"
        @onSearch="
          $emit('onSearch', { field: searchModel, value: searchValue })
        "
        @onSensorChange="
          $emit('onSensorTypeSearch', { field: searchModel, value: $event })
        "
      />
      <UnitsAddButton
        v-if="activeTab !== sections.TRAILERS && activeTab === 'objects'"
        :active-tab="activeTab"
        @click.native="$emit('onCreate')"
      />
      <UnitsAddButton
        v-if="activeTab !== sections.TRAILERS && activeTab === 'groups'"
        :active-tab="activeTab"
        @click.native="$emit('onCreateGroup')"
      />
      <UnitsAddButton
        v-if="activeTab !== sections.TRAILERS && activeTab === 'links'"
        :active-tab="activeTab"
        @click.native="$emit('onCreateLink')"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'

import InformationIcon from '../assets/icons/information.vue'
import HistoryIcon from '../assets/icons/history.vue'
import CloseIcon from '../assets/icons/close.vue'
import BackIcon from '../assets/icons/back.vue'
import UnitsSearchInput from './UnitsSearchInput.vue'
import UnitsAddButton from './UnitsAddButton.vue'

import sections from '@/enums/newUnits/main-sections.js'
import {
  objectsOptions,
  groupsOptions,
  linksOptions,
  trailersOptions
} from '@/constants'

const { useGetters } = createVuexHelpers()

const { activeObject, lastSearchKey, lastTypeSearch } = useGetters('units', [
  'activeObject',
  'lastSearchKey',
  'lastTypeSearch'
])

const props = defineProps({
  activeTab: {
    type: String,
    default: ''
  },
  isModify: {
    type: Boolean,
    default: false
  }
})

const $t = useI18n()

const objectName = computed(() => {
  return activeObject.value ? activeObject.value.name : ''
})

const searchOptions = computed(() => {
  switch (props.activeTab) {
    case sections.OBJECTS:
      return objectsOptions
    case sections.GROUPS:
      return groupsOptions
    case sections.TRAILERS:
      return trailersOptions
    case sections.ACCESS_LINKS:
      return linksOptions
    default:
      return ''
  }
})

const searchValue = ref(lastSearchKey.value)
const defaultSearchModel = computed(() => {
  return lastTypeSearch.value
    ? lastTypeSearch.value
    : searchOptions.value.length
    ? searchOptions.value[0].value
    : ''
})

const searchModel = ref(defaultSearchModel.value)

watch(
  () => props.activeTab,
  () => {
    searchValue.value = ''
    searchModel.value = defaultSearchModel.value
  }
)
</script>
