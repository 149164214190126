import Vue from 'vue'

export const objectsOptions = Object.freeze([
	{ key: Vue.i18n.translate('new_units.objects.search_options.name'), value: 'name' },
	{ key: Vue.i18n.translate('new_units.objects.search_options.imei'), value: 'imei' },
	{ key: Vue.i18n.translate('new_units.objects.search_options.phonenumber'), value: 'phonenumber' },
	{ key: Vue.i18n.translate('new_units.objects.search_options.custom_fields'), value: 'custom_fields' },
	{ key: Vue.i18n.translate('new_units.objects.search_options.sensors_name'), value: 'sensors.name' },
	{ key: Vue.i18n.translate('new_units.objects.search_options.sensors_type'), value: 'sensors.type.key' },
	{ key: Vue.i18n.translate('new_units.objects.search_options.sensors_param'), value: 'sensors.param' },
	{ key: Vue.i18n.translate('new_units.objects.search_options.events'), value: 'events' }
])

export const groupsOptions = Object.freeze([
	{ key: Vue.i18n.translate('new_units.groups.search_options.name'), value: 'name' },
	{ key: Vue.i18n.translate('new_units.groups.search_options.object'), value: 'unit' }
])

export const trailersOptions = Object.freeze([
	{ key: Vue.i18n.translate('new_units.trailers.search_options.name'), value: 'name' },
	{ key: Vue.i18n.translate('new_units.trailers.search_options.code'), value: 'code' }
])

export const linksOptions = Object.freeze([
	{ key: Vue.i18n.translate('new_units.links.search_options.object'), value: 'unit' },
	{ key: Vue.i18n.translate('new_units.links.search_options.status'), value: 'status' }
])