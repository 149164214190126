<template>
  <div
    class="flex flex-col justify-between object-sensors__list w-1/3 gap-3 py-4"
  >
    <div class="flex flex-col gap-3 h-full overflow-auto">
      <div class="flex justify-between items-center pl-5 pr-3">
        <div>
          <el-checkbox
            v-model="checkAll"
            :disabled="isCheckAllDisabled"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
          />

          <span class="text-base ml-2">{{
            $t('new_units.objects.fields.sensors.title')
          }}</span>
        </div>
        <div
          v-if="!checkAll"
          class="flex justify-between items-center pl-5 pr-3"
        >
          <button data-test-id="show-modal-sensor"
              class="mr-1"
              title="Копировать датчики"
              @click="showModalSensor">
            <AddRectangle
              style="cursor: pointer"
            />
          </button>
          <button
            data-test-id="add-new-sensor"
            class="ml-1"
            @click="$emit('onAdd')">
            <UnitsAddButton />
          </button>
        </div>
        <UnitsDeleteButton
          v-else
          :deleted-count="checkedSensorsCount.length"
          @onDelete="handleDelete"
        />
      </div>
      <div v-if="data.length">
        <draggable
          v-model="dataCopy"
          :disabled="checkAll"
          class="draggable-list"
          ghost-class="ghost"
          drag-class="drag"
          chosen-class="chosen"
          @change="dragItem"
        >
          <div
            v-for="(item, idx) in data"
            :key="item.id"
            :class="[
              'object-sensors__list__item',
              'flex',
              'justify-between',
              'items-center',
              'pl-5',
              'pr-3',
              'cursor-pointer',
              {
                'bg-lightGrey-100': idx % 2 === 0,
                error: selectedSensor.id === item.id && !!errors.length,
                hidden: recovered.includes(item.id)
              }
            ]"
            @mouseover="hoverHandler({ active: true, id: item.id })"
            @mouseleave="hoverHandler({ active: false, id: item.id })"
            @click="$emit('onChange', { id: item.id, idx })"
          >
            <div class="flex items-center gap-2">
              <UnitsCheckbox
                v-if="checkAll"
                :value="sensorCheckboxes[idx].checked"
                @onChange="toogleCheckbox(idx, $event)"
                @click.native.stop
              />
              <div v-else class="flex items-center gap-1"><DragIcon /></div>
              <div
                v-if="selectedSensor === item.id"
                class="object-sensors__list__item__active rounded-full"
              />
              <div class="flex flex-col">
                <el-tooltip
                  :open-delay="700"
                  effect="dark"
                  :content="item.name"
                  placement="bottom"
                  :offset="2"
                  :visible-arrow="false"
                >
                  <span
                    :class="[
                      'max-w-textLong',
                      'truncate',
                      {
                        active: selectedSensor === item.id,
                        recovered: recovered.includes(item.id)
                      }
                    ]"
                  >
                    {{ item.name }}
                  </span>
                </el-tooltip>
                <span class="text-xs text-grey-300">
                  {{ hiddenStatus(item) }}
                </span>
              </div>
            </div>
            <div
              v-if="
                (isHovered.active && isHovered.id === item.id) ||
                selectedSensor === item.id ||
                recovered.includes(item.id)
              "
              class="object-sensors__list__item__remove cursor-pointer"
            >
              <DeleteIcon
                :class="{ recovered: recovered.includes(item.id) }"
                @click.native.stop="$emit('onDelete', item)"
              />
            </div>
          </div>
        </draggable>
      </div>
      <div v-else class="noSensors">
        <div
          class="noSensors-scroll flex flex-col gap-5 items-center justify-center h-full"
        >
          <div class="noSensors-info">
            <NoSensorsIcon />
            <span class="greyToneSecond">{{
              $t('new_units.objects.fields.sensors.no_sensors')
            }}</span>
          </div>
          <div class="noSensors-buttons flex flex-col gap-2 items-center">
            <el-button class="create-sensor" @click="$emit('onAdd')"
              >Создать первый датчик</el-button
            >
            <el-button
              class="copy-sensor"
              @click="showModalSensors = !showModalSensors"
              >Копировать из другого объекта</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <UnitsDeletedRecovery
      v-if="recovered.length"
      :count="recovered.length"
      :active-tab="activeTab"
      @onCancel="$emit('onCancel')"
      @onDeleteRecovered="$emit('onDeleteRecovered')"
    />
    <ModalSensors
      @close="showModalSensors = false"
      v-if="showModalSensors"
      v-model="showModalSensors"
      @onSubmit="$emit('addCopySensors', $event), (showModalSensors = false)"
    />
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue'

import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'
import draggable from 'vuedraggable'

import UnitsAddButton from '@/components/unitsNew/components/UnitsAddButton'
import UnitsCheckbox from '@/components/unitsNew/components/UnitsCheckbox'
import AddRectangle from '@/components/unitsNew/assets/icons/add-rectangle.vue'
import UnitsDeletedRecovery from '@/components/unitsNew/components/UnitsDeletedRecovery.vue'
import ModalSensors from '@/components/unitsNew/components/modalSensors/modalSensors.vue'
import UnitsDeleteButton from '@/components/unitsNew/components/UnitsDeleteButton.vue'

import DragIcon from '@/components/reports/icons/DragIcon.vue'
import DeleteIcon from '@/components/unitsNew/assets/icons/delete.vue'
import NoSensorsIcon from '@/components/unitsNew/assets/icons/no-sensors.vue'

const { useGetters } = createVuexHelpers()
const { errors } = useGetters('units', ['errors'])
const $t = useI18n()

const isHovered = ref({
  active: false,
  name: null
})
const showModalSensors = ref(false)

const showModalSensor = () => {
  console.log('selectedSensor', props.data)
  showModalSensors.value = true
}

const activeTab = 'sensors'

const props = defineProps({
  data: {
    type: Array,
    default() {
      return []
    }
  },
  selectedSensor: {
    type: String,
    default: ''
  },
  recovered: {
    type: Array,
    default: () => []
  },
  isAllChecked: {
    type: Boolean,
    default: false
  }
})

const checkAll = ref(false)
const isIndeterminate = ref(false)
const sensorCheckboxes = ref([])

const dataCopy = ref(props.data)
const hiddenStatus = (item) => {
  if (item.hide_in_reports && item.hide_in_monitoring) {
    return $t('new_units.objects.fields.sensors.fields.hidden_in_both')
  }
  if (item.hide_in_reports) {
    return $t('new_units.objects.fields.sensors.fields.hidden_in_report')
  }
  if (item.hide_in_monitoring) {
    return $t('new_units.objects.fields.sensors.fields.hidden_in_monitoring')
  }

  return ''
}

const hoverHandler = (value) => {
  isHovered.value = value
}
const emits = defineEmits([
  'onAllChecked',
  'updateData',
  'onToggleCheckbox',
  'onDelete'
])
const dragItem = (value) => {
  emits('updateData', { data: dataCopy.value, idx: value.moved.newIndex })
}

const handleCheckAllChange = (val) => {
  isIndeterminate.value = false

  emits('onAllChecked', val)
}

const isAllSensorsUnchecked = computed(() => {
  return sensorCheckboxes.value.every((item) => !item.checked)
})

const isAllSensorsChecked = computed(() => {
  return sensorCheckboxes.value.every((item) => item.checked)
})

const checkedSensorsCount = computed(() => {
  return sensorCheckboxes.value.filter(
    (item) => item.checked && !props.recovered.includes(item.id)
  )
})

const isCheckAllDisabled = computed(() => {
  return !props.data.length || props.recovered.length === props.data.length
})

const toogleCheckbox = (idx, value) => {
  sensorCheckboxes.value[idx].checked = value

  isIndeterminate.value = isAllSensorsChecked.value ? false : true

  if (isAllSensorsUnchecked.value) {
    isIndeterminate.value = false
    checkAll.value = false
    emits('onAllChecked', false)
  }
}

const handleDelete = () => {
  checkedSensorsCount.value.forEach((item) => emits('onDelete', item))
}

const generateCheckboxes = () => {
  sensorCheckboxes.value = Array.from(props.data, (item) => {
    return { id: item.id, checked: true }
  })
}

watch(
  () => props.isAllChecked,
  (val) => {
    checkAll.value = val
    generateCheckboxes()
  }
)

watch(
  () => props.recovered,
  (val) => {
    if (!props.data.length) return (checkAll.value = false)
    generateCheckboxes()
  },
  {
    deep: true
  }
)
</script>
