var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-collapse',{staticClass:"object-sensors__details__technical__parameters mt-6",on:{"change":_setup.openParam}},[_c('el-collapse-item',[_c('template',{slot:"title"},[_c('div',{staticClass:"flex justify-between items-center w-full"},[_c('div',{staticClass:"flex items-center gap-1"},[_c('span',[_vm._v(" "+_vm._s(_setup.$t( 'new_units.objects.fields.sensors.fields.technical_params.title' ))+" ")]),_c('span',{staticClass:"text-grey-200"},[_vm._v(" ("+_vm._s(_setup.resultArray.length)+") ")])]),_c('div',{staticClass:"flex justify-center items-center w-5 h-5 rounded-full bg-lightGrey-200"},[_c(_setup.ArrowDownIcon,{class:[
              'arrow-icon',
              'transform',
              _setup.isOpen ? 'rotate-90' : 'rotate-0'
            ]})],1)])]),(_setup.resultArray.length)?_c('el-form',{ref:_setup.refs.sensorTechParametersValidation,attrs:{"model":_setup.model}},[_c('ul',_vm._l((_setup.resultArray),function(item,index){return _c('li',{key:index,staticClass:"valueRow items-center"},[_c('label',{staticClass:"labelRow leading-4",attrs:{"for":"value"}},[_vm._v(" "+_vm._s(item.name)+": ")]),_c('div',{staticClass:"flex items-center gap-4"},[_c('div',{staticClass:"text-field"},[_c('el-form-item',{attrs:{"rules":_setup.parameterRule,"prop":`value__${item.key}`}},[_c('el-input',{attrs:{"id":"value","type":"text"},on:{"input":function($event){return _setup.validateInput(item, index, 'value')}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)],1),_c('el-tooltip',{attrs:{"content":`${_setup.$t(
                'new_units.objects.fields.sensors.fields.technical_params.show_annotation'
              )}`}},[_c(_setup.UnitsSwitch,{staticClass:"switchRow",attrs:{"value":item.show_in_monitoring},on:{"onChange":function($event){return _setup.changeField($event, index, 'show_in_monitoring')}}})],1),_c(_setup.TrashIcon,{staticClass:"deleteIcon",nativeOn:{"click":function($event){return _setup.deleteFields(item)}}})],1)])}),0)]):_vm._e(),_c('div',{staticClass:"add-tech-params px-4"},[_c(_setup.AddTechParamsIcon,{staticClass:"'add-params-icon'",class:{
          'add-params-icon--grey': _setup.props.fields.length === _setup.techFields.length
        },nativeOn:{"click":function($event){return _setup.openTechFields.apply(null, arguments)}}}),_c('span',{class:[
          'title',
          { 'title--grey': _setup.props.fields.length === _setup.techFields.length }
        ],on:{"click":_setup.openTechFields}},[_vm._v(" "+_vm._s(_setup.$t( 'new_units.objects.fields.sensors.fields.technical_params.add_params' ))+" ")])],1),(_setup.isTechFieldsOpen)?_c('ul',{staticClass:"select-tech-fields"},_vm._l((_setup.techFields),function(item){return _c('li',{key:item.key,staticClass:"select-name",class:{ disabled: _setup.isFieldAlreadyAdded(item) },on:{"click":function($event){return _setup.addFields(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }