<template>
  <div class="object-basic overflow-auto flex gap-3 p-5">
    <div class="object-basic__info w-1/2">
      <el-form :model="data" :ref="refs.basicValidation" class="flex flex-col gap-4 pb-4">
        <div class="relative">
          <span class="object-basic__info__label input-label">{{ $t('new_units.objects.fields.basic.name') }}</span>
          <el-form-item :rules="rules.name" prop="name">
            <el-input v-model="data.name" autocomplete="off" />
          </el-form-item>
        </div>
        <div class="relative">
          <span class="object-basic__info__label input-label">{{ $t('new_units.objects.fields.basic.type') }}</span>
          <el-form-item :rules="rules.type" prop="type">
            <el-select v-model="data.type" filterable :placeholder="$t('new_units.placeholder.select')">
              <el-option v-for="item in unitTypes" :key="item.key" :label="item.value" :value="item" />
            </el-select>
          </el-form-item>
        </div>
        <!-- HIDE FOR NOW -->
        <!-- <div class="relative">
					<span class="object-basic__info__label input-label">{{ $t('new_units.objects.fields.basic.gadget') }}</span>
					<el-form-item :rules="rules.terminal_type" prop="terminal_type">
						<el-select v-model="data.terminal_type" :placeholder="$t('new_units.placeholder.select')" @change="setPort">
							<el-option
								v-for="item in terminalType"
								:key="item.key"
								:label="item.value"
								:value="item"
							/>
						</el-select>
					</el-form-item>
				</div> -->
        <!-- <div class="object-basic__info__note flex items-center gap-3 text-xs font-normal rounded-xl">
					<NoteInfoIcon />
					<span>
						{{ $t('new_units.objects.fields.basic.note') }}
					</span>
				</div> -->
        <div class="relative terminal-type-select">
          <span class="object-basic__info__label input-label">{{ $t('new_units.objects.fields.basic.protocol') }}</span>
          <el-form-item :rules="rules.terminal_type" prop="terminal_type">
            <el-select v-model="data.terminal_type" filterable :placeholder="$t('new_units.placeholder.select')" @change="setPort">
              <el-option v-for="item in terminalType" :key="item.key" :label="item.value" :value="item" />
            </el-select>
          </el-form-item>

          <div class="flex flex-col pl-4 text-xs font-normal greyToneThird server-block">
            <span>{{ $t('new_units.objects.fields.basic.server') }} {{ currentPort.server }}</span>
            <span>{{ $t('new_units.objects.fields.basic.port') }} {{ currentPort.port }}</span>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="w-10/12 relative">
            <span class="object-basic__info__label input-label">{{ $t('new_units.objects.fields.basic.imei') }}</span>

            <el-form-item v-if="!isEditingImei" prop="imei">
              <el-select v-model.number="data.imei" :placeholder="$t('new_units.placeholder.select')" :allow-create="allowCreateIMEI" :filter-method="filterImei" filterable class="imei-select" :disabled="!terminals" @change="addCustomImei">
                <el-option v-for="item in imeisList" :key="item.id" :label="item.imei" :value="item.imei" />
              </el-select>

              <AddWorkType v-if="customImei.length && allowCreateIMEI" class="object-basic__info__custom-imei cursor-pointer" @click.native="addCustomImei" />
            </el-form-item>
            <div v-if="terminals" class="flex flex-col">
              <!-- EDIT EMEI MOD -->
              <div ref="editInputRef" class="object-basic__edit-imei-container" :class="{ active: isEditingImei }" v-if="isEditingImei">
                <el-input v-model="editedImei" @input="handleChanges" :class="{ 'input-error': editedImeiError.length }" />
                <span class="object-basic__edit-imei-container__btn" @click="saveEditedImei">{{ $t('save') }}</span>
              </div>

              <span class="object-basic__error-text" v-if="editedImeiError.length">{{ editedImeiError }}</span>
              <!-- Редактировать выбранный IMEI -->
              <span v-if="!editedImeiError.length && data.imei" @click.stop="!editedImeiError.length && toggleEditImei(true)" class="object-basic__info__edit-imei" :class="{ disabled: isEditingImei }">
                {{ $t('objects.editImei') }}
              </span>

              <!-- Сгенерировать виртуальный IMEI  -->
              <span v-if="allowCreateIMEI" @click="generateImei" class="object-basic__info__generate-imei">
                {{ $t('objects.generate.imei') }}
              </span>
            </div>
            <!-- EDIT IMEI MOD -->
          </div>
          <div class="object-basic__info__copy flex items-center justify-center rounded-xl cursor-pointer">
            <template v-if="!isEditingImei">
              <CopyIcon @click.native="copyImei" :class="{ clicked: isCopyClicked }" />
            </template>
          </div>
          <el-popover trigger="hover" v-if="terminalIsBlocked" popper-class="terminal-blocked-popover" placement="bottom">
            <TerminalBlockedIcon slot="reference" class="object-terminal-blocked" />
            <p>
              {{ $t('terminal.blocked') }}
            </p>
          </el-popover>
        </div>
        <div class="flex gap-3">
          <div class="relative">
            <span class="object-basic__info__label input-label">{{ $t('new_units.objects.fields.basic.sim_1') }}</span>
            <el-input v-model="data.phonenumber" type="number" />
          </div>
          <div class="relative">
            <span class="object-basic__info__label input-label">{{ $t('new_units.objects.fields.basic.sim_2') }}</span>
            <el-input v-model="data.phonenumber2" type="number" />
          </div>
        </div>
      </el-form>
    </div>
    <div class="object-basic__icon w-1/2">
      <div class="object-basic__icon__map">
        <div class="skif-marker__img-box absolute inset-y-5 text-borderColor">
          <span class="skif-marker__item">
            <svg class="skif-marker__img" width="56" height="50" viewBox="0 -4 56 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <use :xlink:href="`/static/car_icon-new/cars.svg#${icon.name}`" :fill="icon.color" />
            </svg>
          </span>
        </div>
        <img :src="getLayerMap" alt="map" />
        <div class="layers">
          <el-radio-group v-model="layer">
            <el-radio-button v-for="layer in layers" :key="layer.name" :label="layer.src">
              {{ layer.label }}
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="mt-5">
        <ObjectIcons :icon="icon" @onSetColor="data.icon.color = $event" @onSetIcon="data.icon.name = $event" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onBeforeMount, getCurrentInstance, onMounted, watch, nextTick, defineEmits } from 'vue'

import { adminPanel, objectsApi } from '@/api'

import ObjectIcons from './ObjectIcons.vue'
import NoteInfoIcon from '@/components/unitsNew/assets/icons/note-info.vue'
import CopyIcon from '@/components/unitsNew/assets/icons/copy.vue'
import AddWorkType from '@/components/unitsNew/assets/icons/add-work_type.vue'
import TerminalBlockedIcon from '@/assets/icons/TerminalBlockedIcon.vue'

import { refs } from '@/components/unitsNew/helpers/index.js'
import { useI18n } from '@/hooks/useI18n'
import { createVuexHelpers } from 'vue2-helpers'
import { copyTextToClipboard } from '@/utils/helpers'
import eventBus from '@/eventBus'

const { useGetters, useActions } = createVuexHelpers()

const { unitsGroup, modalType, activeObject } = useGetters('units', ['unitsGroup', 'modalType', 'activeObject'])

const { unitTypes, terminalType } = useGetters('dictionary', ['unitTypes', 'terminalType'])
const { ports } = useGetters('ports', ['ports'])
const { terminals } = useGetters('terminal', ['terminals'])
const { role, activeCompanyId } = useGetters('login', ['role', 'activeCompanyId'])
const imeisList = ref([])
const $t = useI18n()
const instance = getCurrentInstance()

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})
const emits = defineEmits(['save-object', 'update-imei'])

const isEditingImei = ref(false)
const editedImei = ref('')
const oldImei = ref('')
const editedImeiError = ref('')
const selectedItemId = ref('')

const rules = ref({
  name: [
    {
      required: true,
      message: $t('new_units.objects.fields.basic.errors.name')
    }
  ],
  type: [
    {
      required: true,
      message: $t('new_units.objects.fields.basic.errors.type')
    }
  ],
  terminal_type: [
    {
      required: true,
      message: $t('new_units.objects.fields.basic.errors.terminal_type')
    }
  ],
  imei: [
    {
      required: true,
      message: $t('new_units.objects.fields.basic.errors.imei')
    }
  ]
})

const currentPort = ref({
  server: '',
  port: ''
})

const icon = ref({ name: '', color: '' })

const isCopyClicked = ref(false)

const customImei = ref('')

const terminalIsBlocked = ref(false)

const layers = [
  { name: 'Yandex Map', src: 'yandex', label: 'Yandex' },
  { name: 'OSM', src: 'osm', label: 'OSM' }
]

const layer = ref('yandex')

const getLayerMap = computed(() => {
  return require(`@/components/unitsNew/assets/images/map_${layer.value}.png`)
})

const removeGroup = group => {
  props.data.groupValue = props.data.groupValue.filter(item => item !== group)
}

const copyImei = async () => {
  isCopyClicked.value = true
  await copyTextToClipboard(props.data.imei)

  setTimeout(() => {
    isCopyClicked.value = false
  }, 1000)
}

const generateImei = async () => {
  let randomImei = null
  await objectsApi.generateRandomImei(
    response => {
      randomImei = response.data.imei
    },
    error => {
      eventBus.$showError(error.response.data.message)
    }
  )

  const filteredImeiList = imeisList.value.filter(item => {
    return item?.id !== props.data.id ? item : null
  })

  filteredImeiList.push({
    id: props.data.id,
    value: randomImei
  })

  imeisList.value = filteredImeiList

  props.data.imei = randomImei
  emits('update-imei', randomImei)
}

const filterImei = value => {
  imeisList.value = terminals.value.filter(item => item.imei.toLowerCase().indexOf(value.toLowerCase()) >= 0)
  customImei.value = value
}
const toggleEditImei = editMode => {
  oldImei.value = props.data.imei
  isEditingImei.value = editMode
  if (editMode) {
    editedImei.value = props.data.imei
  }
}

const handleChanges = () => {
  editedImei.value = editedImei.value.replace(/[^0-9]/g, '')
}
const saveEditedImei = async () => {
  const currentEditedImei = editedImei.value

  try {
    const isExist = imeisList.value.find(item => item.imei === currentEditedImei)

    if (isExist) {
      isEditingImei.value = false
      return
    }

    const newTerminal = {
      company_id: activeCompanyId.value,
      imei: String(currentEditedImei),
      id: selectedItemId.value || props.data.id
    }

    const data = await adminPanel.editTerminal(newTerminal)

    if (!data.is_blocked) {
      props.data.imei = editedImei.value
      editedImeiError.value = ''
      instance.proxy.$showSuccess($t('new_units.links.success.edit_imei'))
      emits('save-object')
    }
  } catch (error) {
    console.log('error', error)
    // props.data.imei = oldImei.value
    // instance.proxy.$showError(error.response.data.message)
    editedImeiError.value = error.response.data.message
  }
}
const addCustomImei = async val => {
  let currentIMEI
  if (Number(val)) {
    currentIMEI = val
  } else {
    currentIMEI = customImei.value
  }
  const isExist = imeisList.value.find(item => item.imei === currentIMEI)

  if (isExist) {
    props.data.imei = currentIMEI
    return
  }
  try {
    const data = await adminPanel.checkImei({
      company_id: activeCompanyId.value,
      imei: currentIMEI
    })

    if (!data.is_blocked) {
      props.data.imei = currentIMEI
      instance.proxy.$showSuccess($t('new_units.links.success.create_imei'))
      customImei.value = ''
    }
  } catch (error) {
    customImei.value = ''
    props.data.imei = ''
    console.log('error', error)
    instance.proxy.$showError(error.response.data.message)
  } finally {
    imeisList.value = terminals.value.slice()
  }
}

const editInputRef = ref(null)
const outsideClickListener = event => {
  if (editInputRef.value && !editInputRef.value.contains(event.target)) {
    toggleEditImei(false)
    editedImeiError.value = ''
    editedImei.value = oldImei.value
  }
}
watch(isEditingImei, async newValue => {
  if (newValue) {
    await nextTick()
    document.addEventListener('click', outsideClickListener)
  } else {
    document.removeEventListener('click', outsideClickListener)
  }
})

const allowCreateIMEI = computed(() => {
  return role.value === 'TARIFF_EDITOR' || role.value === 'CONTROLLER'
})

const setPort = type => {
  const port = ports.value.find(item => item.terminal === type.key)

  if (port) currentPort.value = port
}

const setIcon = () => {
  const separateIcon = activeObject.value.icon.key.split('_')

  icon.value.color = ref(separateIcon[separateIcon.length - 1])
  icon.value.name = ref(separateIcon.filter((item, idx) => idx !== separateIcon.length - 1).join('_'))

  if (!props.data.icon.color && !props.data.icon.name) {
    props.data.icon.color = icon.value.color
    props.data.icon.name = icon.value.name
  } else {
    icon.value.color = props.data.icon.color
    icon.value.name = props.data.icon.name
  }
}

onBeforeMount(() => {
  if (modalType.value === 'edit') {
    setIcon()
    setPort(props.data.terminal_type)
  } else {
    icon.value = props.data.icon
  }

  imeisList.value = terminals.value.slice()
})

onMounted(() => {
  const isExist = imeisList.value.find(item => item.imei === props.data.imei)
  if (isExist) selectedItemId.value = isExist.id

  const imeiSelect = document.querySelector('.imei-select .el-input__inner')
  imeiSelect.type = 'number'

  const terminalImei = props.data.imei

  terminals.value.map(item => {
    if (item.imei === terminalImei && item?.is_blocked) {
      terminalIsBlocked.value = true
    }
  })
})
</script>
<style>
.terminal-blocked-popover {
  padding: 6px 8px 6px 8px;
  border-radius: 6px;
  background: #dbebff;
  color: #2767b6;
  font-family: 'Source Sans Pro', serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  border: none;
  cursor: default;
}
.object-terminal-blocked {
  cursor: pointer;
  position: absolute;
  left: 288px;
  transform: translateY(18px);
  fill: none;
}
</style>
<style lang="scss">
@import '@/components/unitsNew/assets/styles/Objects/Basic.scss';
</style>
